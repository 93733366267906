import type { Ref } from 'vue'
import type { FetchOptions } from 'ofetch'
import { useAccessToken, useLogout } from '~/composables/auth'

export function useFetchOptions (opts?: any) {
  const accessToken = useAccessToken()
  const config = useRuntimeConfig()

  return computed<FetchOptions>(() => ({
    headers: {
      ...(accessToken.value
        ? { Authorization: `Bearer ${accessToken.value}` }
        : {}
      ),
      Accept: 'application/json'
    },
    baseURL: config.public.dubbUrl,
    onResponseError ({ response }) {
      const logout = useLogout()

      switch (response.status) {
        case 401:
          logout()
          break
        case 500:
          throw createError({
            statusCode: response.status,
            statusMessage: 'Server error',
            fatal: true
          })
        default:
          throw createError({
            statusCode: response.status,
            statusMessage: response._data?.message || 'There is something wrong',
            data: response._data,
            fatal: true
          })
      }
    },
    ...opts
  }))
}

export function useApiFetch<T> (request: Ref<string> | string, opts?: any) {
  const fetchOptions = useFetchOptions(opts)
  // @ts-ignore
  return useFetch<T>(request, fetchOptions.value as UseFetchOptions<T>)
}

export function apiFetch<T> (request: string, opts?: any) {
  const fetchOptions = useFetchOptions(opts)
  // @ts-ignore
  return $fetch<T>(request, fetchOptions.value)
}
