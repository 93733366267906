import { useLocalStorage, StorageSerializers } from '@vueuse/core'
import cryptoRandomString from 'crypto-random-string'
import trimEnd from 'lodash/trimEnd'
import { resolveApiRoute } from '@dubb-app/core'
import { apiFetch } from '~/composables/apiFetch'

export const useAuth = () => {
  return useLocalStorage<AuthData>('auth', null, {
    shallow: true,
    serializer: StorageSerializers.object
  })
}

export function useAccessTokenExpired () {
  const auth = useAuth()
  return computed(() => {
    const expiredAt = auth.value?.expired_at
    if (expiredAt) {
      const now = Math.floor(Date.now() / 1000)
      return now > expiredAt
    }

    return true
  })
}

export const useAccessToken = () => {
  const auth = useAuth()
  const isExpired = useAccessTokenExpired()
  return computed(() => {
    if (isExpired.value) {
      return
    }

    return auth.value?.access_token
  })
}

export const useLogout = () => {
  const auth = useAuth()

  return () => {
    auth.value = null
    return navigateTo('/login')
  }
}

export const useCodeChallenge = () => {
  const loginState = useLocalStorage('auth.loginState', '')
  const codeVerifier = useLocalStorage('auth.codeVerifier', '')

  const generateCodeChallenge = async () => {
    loginState.value = cryptoRandomString({ length: 40 })
    codeVerifier.value = cryptoRandomString({ length: 128 })

    const textEncoder = new TextEncoder()
    const data = textEncoder.encode(codeVerifier.value)

    const hash = await crypto.subtle.digest('SHA-256', data)

    let codeChallenge = window.btoa(String.fromCharCode(...new Uint8Array(hash)))
    codeChallenge = trimEnd(codeChallenge, '=')
    codeChallenge = codeChallenge.replaceAll('+', '-')
    codeChallenge = codeChallenge.replaceAll('/', '_')

    return codeChallenge
  }

  return {
    generateCodeChallenge,
    loginState,
    codeVerifier
  }
}

function storeAuthData (authRes: OauthTokenResponse) {
  const auth = useAuth()

  auth.value = {
    ...authRes,
    expired_at: authRes.expires_in + Math.floor(Date.now() / 1000)
  }
}

export async function requestToken (code: string, state: string) {
  const config = useRuntimeConfig()
  const { codeVerifier, loginState } = useCodeChallenge()

  if (loginState.value !== state) {
    throw createError({
      statusCode: 400,
      statusMessage: 'Invalid state'
    })
  }

  const res = await apiFetch<OauthTokenResponse>(resolveApiRoute('passport.token'), {
    method: 'POST',
    body: {
      grant_type: 'authorization_code',
      client_id: config.public.dubbClientId,
      redirect_uri: config.public.dubbRedirectUri,
      code_verifier: codeVerifier.value,
      code
    }
  })

  storeAuthData(res)
}

export async function checkAccessTokenStatus () {
  const isExpired = useAccessTokenExpired()

  if (isExpired.value) {
    await refreshToken()
  }
}

export async function refreshToken () {
  const config = useRuntimeConfig()
  const auth = useAuth()

  if (auth.value != null) {
    const res = await apiFetch<OauthTokenResponse>(resolveApiRoute('passport.token'), {
      method: 'POST',
      body: {
        grant_type: 'refresh_token',
        refresh_token: auth.value.refresh_token,
        client_id: config.public.dubbClientId
      }
    })

    storeAuthData(res)
  }
}
